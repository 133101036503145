<template>
  <el-dialog
    class="edit"
    :title="title"
    :visible="editDialog"
    center
    @close="dialogClose"
    width="900px"
    :close-on-click-modal="false"
  >
    <el-form
      :model="formData"
      ref="editDepartment"
      :rules="rules"
      class="addDailog"
      onsubmit="return false;"
      label-width="140px"
      label-position="right"
    >
      <el-form-item label="部门名称" prop="name">
        <el-input
          type="text"
          show-word-limit
          v-model="formData.name"
        ></el-input>
      </el-form-item>
      <el-form-item label="部门描述" prop="description">
        <el-input
          type="text"
          show-word-limit
          v-model="formData.description"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="dialog-footer">
      <el-button @click="dialogClose">取消</el-button>
      <el-button type="primary" @click="sure">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {
  addDepartment,
  editDepartment,
  departmentDetail,
} from "@/api/department";

export default {
  name: "edit",
  data() {
    return {
      title: "新增",
      token: null,
      formData: {},
      rules: {
        name: [{ required: true, message: "请输入部门名称", trigger: "blur" }],
        description: [
          { required: true, message: "请输入部门描述", trigger: "blur" },
        ],
      },
    };
  },
  props: {
    type: String,
    editDialog: Boolean,
    id: String,
    name: String,
  },
  components: {},
  created() {
    // this.getQualityList();
    if (this.type == "edit") {
      this.title = "编辑";
      this.departmentDetail();
    } else if (this.type == "add") {
      this.title = "新增";
    }
  },
  methods: {
    //弹窗关闭
    dialogClose() {
      this.formData = {};
      this.$emit("listenEditClose", { type: "cancel", isClose: true });
    },

    departmentDetail() {
      departmentDetail(this.id).then((response) => {
        if (response.success) {
          this.formData = response.data;
        }
      });
    },
    addDepartment() {
      addDepartment(this.formData).then((response) => {
        if (response.success) {
          this.$message({ type: "success", message: "添加成功!" });
          this.formData = {};
          this.$emit("listenEditClose", { type: "sure", isClose: true });
        }
      });
    },
    editDepartment() {
      editDepartment(this.formData).then((response) => {
        if (response.success) {
          this.$message({ type: "success", message: "编辑成功!" });
          this.formData = {};
          this.$emit("listenEditClose", { type: "sure", isClose: true });
        }
      });
    },
    //确定
    sure() { 
      console.log(this.formData);
      this.$refs["editDepartment"].validate((valid) => {
        if (valid) {
          if (this.type == "add") {
            this.addDepartment();
          } else if (this.type == "edit") {
            this.editDepartment();
          }
        } else {
          console.log("-------------error");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.bigTitle {
  font-size: 20px;
  margin-bottom: 15px;
}
.bar {
  overflow: hidden;
  padding: 10px;
  li {
    min-height: 50px;
    line-height: 50px;
    font-size: 15px;
    list-style: none;
    float: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    span {
      display: inline-block;
      width: 120px;
      text-align: right;
    }
    .childWidth {
      width: calc(100% - 150px);
    }
  }
  .width50 {
    width: 50%;
  }
  .width100 {
    width: 100%;
    p {
      width: calc(100% - 120px);
      float: right;
      span {
        display: inline;
        padding: 0 10px;
      }
    }
  }
}

.addDailog {
  // padding: 0 0 20px 0;
  display: flex;
  flex-wrap: wrap;
  .roleCodeList {
    width: 100% !important;
    height: auto;
    text-align: center !important;
  }
  .el-form-item {
    text-align: left;

    // display: flex;
    width: 100%;
    // float: left;
    min-height: 1rem;
    .el-select {
      width: 100% !important;
    }
    .el-input-number {
      width: 100% !important;
      text-align: left !important;
      .el-input__inner {
        text-align: left !important;
      }
    }
  }
}
</style>