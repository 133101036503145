/*
 * @Description: 
 * @Author: transparent
 * @Date: 2021-12-31 11:17:14
 * @LastEditTime: 2022-01-05 10:00:50
 * @LastEditors: transparent
 * @FilePath: /hermes-web/src/api/department.js
 */
import request from '@/utils/request'

//条件查询部门集合
export function departmentList(param) {
    return request({
        url: '/admin/department/queryList',
        method: 'post',
        data:param
    })
}

export function addDepartment(param) {
    return request({
        url: '/admin/department/add',
        method: 'post',
        data: param
    })
}

export function editDepartment(param) {
    return request({
        url: '/admin/department/edit',
        method: 'post',
        data: param
    })
}


export function departmentDetail(param) {
    return request({
        url: '/admin/department/findDetail',
        method: 'post',
        data:  {
            id: param,
        }
    })
}

//批量编辑用户和部门的关系
export function editDepartmentRelation(param) {
    return request({
        url: '/admin/department/relation/edit',
        method: 'post',
        data:param
    })
}

//条件查询部门列表(无分页)
export function selectListDepartment(param) {
    return request({
        url: '/admin/department/findList',
        method: 'post',
        data:param
    })
}


// 删除部门
export function deleteDepartment(param) {
    return request({
        url: '/admin/department/delete',
        method: 'post',
        data:param
    })
}