<template>
  <div>
    <el-main>
      <el-form>
        <el-row type="flex" justify="end">
          <el-form-item>
            <el-button
              type="primary"
              @click="openDialog('add')"
              v-permission="['admin:department:add']"
              >新增</el-button
            >
          </el-form-item>
        </el-row>
      </el-form>

      <!-- 表格 -->
      <el-table border :data="tableData">
        <el-table-column type="index" label="#" width="40"></el-table-column>
        <el-table-column label="部门名称" prop="name"></el-table-column>
        <el-table-column label="部门成员" prop="place">
          <template slot-scope="scope">
            <span
              class="span-tag-list"
              v-for="(item, index) in scope.row.userList"
              :key="item.id"
            >
              <el-tooltip
                class="item"
                effect="dark"
                :content="item.realName"
                placement="top"
              >
                <span>{{ index + 1 }}.{{ item.realName }} </span>
              </el-tooltip>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="负责人" prop="place">
          <template slot-scope="scope">
            <span
              class="span-tag-list"
              v-for="(item, index) in scope.row.leaderList"
              :key="item.id"
            >
              <el-tooltip
                class="item"
                effect="dark"
                :content="item.realName"
                placement="top"
              >
                <span>{{ index + 1 }}.{{ item.realName }} </span>
              </el-tooltip>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="描述" prop="description"></el-table-column>

        <el-table-column label="操作" fixed="right" width="340">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="openDialog('edit', scope.row.id)"
              v-permission="['admin:department:edit']"
              >编辑</el-button
            >
            <el-button
              type="primary"
              size="mini"
              @click="openDialog('relation', scope.row.id)"
              v-permission="['admin:department:relation:edit']"
              >指派成员</el-button
            >
            <el-button
              type="primary"
              size="mini"
              @click="openDialog('relationCharge', scope.row.id)"
              v-permission="['admin:department:relation:edit']"
              >指派负责人</el-button
            >
            <el-button
              type="primary"
              size="mini"
              @click="deleteDepartment(scope.row.id, scope.row.name)"
              v-permission="['admin:department:delete']"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="page-info-class">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="this.pageInfo.pageNum"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="this.pageInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="this.pageInfo.total"
        ></el-pagination>
      </div>
    </el-main>
    <!-- 添加 -->
    <!-- <add
      v-if="addDialog"
      :addDialog="addDialog"
      type="add"
      v-on:listenAddClose="listenAddClose" 
    ></add> -->
    <!-- 编辑 -->
    <edit
      v-if="editDialog"
      :editDialog="editDialog"
      :type="typeDialog"
      v-on:listenEditClose="listenEditClose"
      :id="departmentId"
    ></edit>

    <relatedPersonnel
      v-if="relatedPersonnelDialog"
      :relatedPersonnelDialog="relatedPersonnelDialog"
      :type="typeDialog"
      v-on:listenRelatedPersonnelClose="listenRelatedPersonnelClose"
      :id="departmentId"
    ></relatedPersonnel>
    <relatedPersonnelCharge
      v-if="relatedPersonnelChargeDialog"
      :relatedPersonnelChargeDialog="relatedPersonnelChargeDialog"
      :type="typeDialog"
      v-on:listenRelatedPersonnelChargeClose="listenRelatedPersonnelChargeClose"
      :id="departmentId"
    ></relatedPersonnelCharge>
  </div>
</template>

<script>
import { departmentList, deleteDepartment } from "@/api/department";
import edit from "../components/department/edit";
import relatedPersonnel from "../components/department/relatedPersonnel";
import relatedPersonnelCharge from "../components/department/relatedPersonnelCharge";

export default {
  name: "department",
  data() {
    return {
      tableData: [],
      editDialog: false, //打开编辑
      addDialog: false,
      relatedPersonnelDialog: false,
      relatedPersonnelChargeDialog: false,
      typeDialog: "",
      roleCode: "",
      departmentId: null,
      pageInfo: {
        total: 0,
        pageNum: 1,
        pageSize: 10,
        pageCount: 1,
      },
    };
  },
  components: {
    edit,
    relatedPersonnel,
    relatedPersonnelCharge,
  },

  mounted() {
    this.queryDepartment(1, this.pageInfo.pageSize);
  },

  methods: {
    deleteDepartment(id, name) {
      this.$confirm("确定要删除部门：" + name + ", 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: false,
      })
        .then(() => {
          deleteDepartment({ id }).then((response) => {
            if (response.success) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.queryDepartment(
                this.pageInfo.pageNum,
                this.pageInfo.pageSize
              );
            } else {
              this.$message({
                type: "info",
                message: response.message,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    handleSizeChange(val) {
      this.queryDepartment(1, val);
    },
    handleCurrentChange(val) {
      if (val > this.pageInfo.pageCount) {
        val = this.pageInfo.pageCount;
      }
      this.queryDepartment(val, this.pageInfo.pageSize);
    },
    queryDepartment(num, pageSize) {
      let param = {};
      param.pageNum = num;
      param.pageSize = pageSize;
      departmentList(param).then((response) => {
        this.tableData = response.data;
        if (response.total != null) {
          this.pageInfo.pageNum = response.pageNum;
          this.pageInfo.pageSize = response.pageSize;
          this.pageInfo.total = response.total;
          this.pageInfo.pageCount = response.pageCount;
        } else {
          this.pageInfo.total = 0;
        }
      });
    },
    openButtonControlDialog(code) {
      this.roleCode = code;
      this.buttonControlDialog = true;
    },
    //新增编辑弹窗显示
    openDialog(type, id, name) {
      console.log(id);
      if (type == "edit") {
        this.typeDialog = "edit";
        this.departmentId = id;
        this.editDialog = true;
      } else if (type == "add") {
        this.typeDialog = "add";
        this.editDialog = true;
      } else if (type == "relation") {
        this.typeDialog = "relation";
        this.departmentId = id;
        this.relatedPersonnelDialog = true;
      } else if (type == "relationCharge") {
        this.typeDialog = "relationCharge";
        this.departmentId = id;
        this.relatedPersonnelChargeDialog = true;
      }
    },
    //监听编辑弹窗关闭
    listenEditClose(params) {
      console.log(params);
      const { type, isClose } = params;
      this.editDialog = !isClose;
      if (type == "sure") {
        this.queryDepartment(this.pageInfo.pageNum, this.pageInfo.pageSize);
      }
    },
    //监听弹窗关闭
    listenRelatedPersonnelClose(params) {
      console.log(params);
      const { type, isClose } = params;
      this.relatedPersonnelDialog = !isClose;
      if (type == "sure") {
        this.queryDepartment(this.pageInfo.pageNum, this.pageInfo.pageSize);
      }
    },
    listenRelatedPersonnelChargeClose(params) {
      console.log(params);
      const { type, isClose } = params;
      this.relatedPersonnelChargeDialog = !isClose;
      if (type == "sure") {
        this.queryDepartment(this.pageInfo.pageNum, this.pageInfo.pageSize);
      }
    },
    //
    // listenAddClose(params) {
    //   console.log(params);
    //   const { type, isClose } = params;
    //   this.addDialog = !isClose;
    //   if (type == "sure") {
    //     this.queryDepartment();
    //   }
    // },
  },
};
</script>

<style scoped>
</style>
