<template>
  <el-dialog
    class="edit"
    :title="title"
    :visible="relatedPersonnelChargeDialog"
    center
    @close="dialogClose"
    width="900px"
    :close-on-click-modal="false"
  >
    <div class="bar">
      <li class="width100">
        <span>关联部门负责人：</span>
        <el-select
          v-model="formData.userIdList"
          multiple
          @visible-change="projectTeam($event)"
          filterable
          placeholder="请选择"
          clearable
          class="childWidth"
          no-data-text="加载中···"
        >
          <el-option
            v-for="item in internalUseroptionsList"
            :key="item.uid"
            :label="item.realName"
            :value="item.uid"
          >
          </el-option>
        </el-select>
      </li>
    </div>

    <div class="dialog-footer">
      <el-button @click="dialogClose">取消</el-button>
      <el-button type="primary" @click="sure">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import Vue from "vue";
import { editDepartmentRelation, departmentDetail } from "@/api/department";
import { projectTeam } from "@/api/projectManage";

export default {
  name: "relatedPersonnelCharge",
  data() {
    return {
      title: "关联部门负责人",
      token: null,
      formData: {
        userIdList: [],
         
      },
      internalUseroptionsList: [],
      formDataType:1,//1 负责人
    };
  },
  props: {
    type: String,
    relatedPersonnelChargeDialog: Boolean,
    id: String,
    name: String,
  },
  components: {},
  created() {
    // this.getQualityList();
    if (this.type == "relationCharge") {
      this.projectTeam(true);
      this.title = "关联部门负责人";
      this.departmentDetail();
      // this.formData.deptId = this.id;
    }
  },
  methods: {
    //
    projectTeam(cb) {
      if (cb) {
        projectTeam({}).then((response) => {
          if (response.success) {
            this.internalUseroptionsList = response.data;
          }
        });
      }
    },
    //弹窗关闭
    dialogClose() {
      this.formData = {};
      this.$emit("listenRelatedPersonnelChargeClose", {
        type: "cancel",
        isClose: true,
      });
    },

    departmentDetail() {
      var that = this;
      departmentDetail(this.id).then((response) => {
        if (response.success) {
          let formData = response.data;
          formData.userIdList = [];
          formData.leaderList.forEach(function (item, index) {
            formData.userIdList[index] = item.uid;
          });
          formData.deptId = formData.id;
          formData.type = this.formDataType;

          this.formData = formData;
        }
      });
    },

    //确定
    sure() {
      let { deptId, userIdList,type } = this.formData;
      console.log(this.formData);

      editDepartmentRelation({ deptId, userIdList,type }).then((response) => {
        if (response.success) {
          this.$message({ type: "success", message: "编辑成功!" });
          this.formData = {};
          this.$emit("listenRelatedPersonnelChargeClose", {
            type: "sure",
            isClose: true,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.bigTitle {
  font-size: 20px;
  margin-bottom: 15px;
}
.bar {
  overflow: hidden;
  padding: 10px;
  li {
    min-height: 50px;
    line-height: 50px;
    font-size: 15px;
    list-style: none;
    float: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    span {
      display: inline-block;
      width: 120px;
      text-align: right;
    }
    .childWidth {
      width: calc(100% - 150px);
    }
  }
  .width50 {
    width: 50%;
  }
  .width100 {
    width: 100%;
    p {
      width: calc(100% - 120px);
      float: right;
      span {
        display: inline;
        padding: 0 10px;
      }
    }
  }
}
 
</style>